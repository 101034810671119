.header {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 15px;
}

.header__mainButtonContainer {
  display: flex;
  flex-direction: column;
}

.headerInformation {
  text-align: right;
}

.header__expertButton {
  background-color: transparent;
  color: #ff5b5b;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 60px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.14em;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
  font-weight: bold;
}

.header__expertLogo {
  font-size: 50px;
}

.header__expertButton_logo {
  padding-left: 26px;
}

.header__expertButton_description {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  letter-spacing: 0.14em;
  color: #c6c6c6;
  margin: 7px 0px;
}

.header__informationContainer {
  text-align: right;
}

.header__informationContainer_address {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.14em;
  color: #2f2f2f;
}

.header__informationContainer_phoneNumber {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 41px;
  color: #ff5b5b;
  margin-top: 7px;
}

.header__informationContainer_timetable {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.14em;
  color: #2f2f2f;
  margin-top: 7px;
  margin-bottom: 10px;
}

.header__underline {
  border-bottom: 1px solid #d8d8d8;
  margin: 0 auto;
}

@media (max-width: 961px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
  }

  .header__informationContainer {
    text-align: center;
    width: 100%;
    margin: 0 10px;
  }
}

@media (max-width: 600px) {
  .header__expertLogo {
    font-size: 36px;
  }

  .header__expertButton_logo {
    height: 36px;
  }

  .header__mainButtonContainer {
    align-items: center;
  }

  .header__expertButton_description {
    margin: 0;
    text-align: center;
  }

  .header__informationContainer_phoneNumber {
    font-size: 30px;
  }
}
