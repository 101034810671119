.InformationContainerComponent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1160px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.InformationContainerComponent__container {
  margin-left: 20px;
  margin-right: 20px;
}

.InformationContainerComponent__header {
  background-color: rgba(255, 255, 255, 0.561);
  width: fit-content;
  padding: 15px 40px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-top: 5px;
  margin-bottom: 20px;
}

.InformationContainerComponent__header_inner {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  margin: 10px;
  margin-bottom: 10px;
  margin-top: 15px;

  color: #383838;
}

.InformationContainerComponent__mainInfo {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 70px;
  padding-bottom: 40px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 14px 14px 45px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

@media (max-width: 961px) {
  .InformationContainerComponent__mainInfo {
    padding: 30px 40px;
  }
}

@media (max-width: 700px) {
  .InformationContainerComponent__container {
    margin: 0;
  }
  .InformationContainerComponent__mainInfo {
    padding: 30px 10px;
  }

  .InformationContainerComponent__header {
    background-color: rgba(255, 255, 255, 0.561);
    width: fit-content;
    padding: 0px 0px;
  }

  .InformationContainerComponent__header_inner {
    font-size: 24px;
    line-height: 32px;
  }
}
