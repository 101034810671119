.footerBlock {
    background: #eaeaea;
    min-height: 74px;
    padding-bottom: 10px;
}

.footerContainer {
    max-width: 1160px;
    margin: 0 auto;
    
}

.footerContainer__inside {
    margin: 0 20px;
}

.footerContainer__statistic {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 49px;
}

.footerContainer_info {
    font-weight: 400;
    font-size: 12px;
    line-height: 115.4%;
    letter-spacing: 0.055em;
}

.footerContainer_statImage {
    margin-right: 7px;
}