.UnderHeaderPhoneMenuComponent {
  display: none;
  width: 100%;
}


.menuButtonsPhone {
  float: right;
}

.menuButtonsAll__makeAnAppointmentButton {
  width: 191px;
  justify-self: end;
  cursor: pointer;
  background-color: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  color: #ffffff;
}

.menuButtonsPhone__menu {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  color: #484848;
  font-size: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 30px;
  cursor: pointer;
  background-color: transparent;
}

.menuPhoneUnderline {
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
}

@media (max-width: 961px) {
  .UnderHeaderPhoneMenuComponent {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-end;
  }

  .menuButtonsPhone {
    width: 100%;
    float: right;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }
}