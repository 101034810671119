.menuButtonPhone {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  color: #484848;
  font-size: 18px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 30px;
  cursor: pointer;
  background-color: transparent;
}

.menuButton__expandArrow {
  padding-left: 6px;
}

.menuButtonPhone:hover {
  background-color: #f5f5f5;
  font-weight: 400;
}
