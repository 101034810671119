.InfoAboutPage_text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.05em;

    color: rgb(78, 78, 78);
}
