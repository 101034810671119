* {
  font-family: inherit; /* все элементы наследуют от своего родителя гарнитуру шрифта */
  font-style: inherit; /* наследуют начертание */
  font-weight: inherit; /* наследуют жирность */
  vertical-align: baseline; /* вертикальное выравнивание по базовой линии */
  font-size: 100%; /* размер шрифта 100% */
  /* background: transparent; /* фон всех элементов прозрачный */
  border: 0; /* убираем рамки */
  outline: 0; /* убираем контуры */
  margin: 0; /* убираем поля */
  padding: 0; 
}

h2, .h2 {
  font-size: 24px; 
 }

 h3, .h3 {
  font-size: 18px; 
 }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
