.sectionComponent__block_carousel {
  max-height: 45rem;
}

.sliderComponent__block {
  height: 100%;
  max-height: 45rem;
  position: initial;
}

.each-slide {
  max-height: 40rem;
  height: 30vw;
  position: initial;
}

.each-slide-img {
  /* max-height: 40rem; */
  height: 100%;
  margin-top: -5px;
  background-position: center top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: initial;
  z-index: -1;
}

.indicator {
  cursor: pointer;
  padding: 7px;
  border-radius: 50px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-top: -5px;
  text-align: center;
  background-color: rgb(218, 218, 218);
}

.indicator.active {
  color: #fff;
  background: rgb(155, 155, 155);
}

@media (max-width: 961px) {
  .sliderComponent__block {
    display: none;
  }
}
