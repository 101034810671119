.ServicesSpecialistsComponent_header {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;

    color: #3281c9;
}

.ServicesSpecialistsComponent__ul {
    margin: 15px 50px;
}

.ServicesSpecialistsComponent__li {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.05em;

    color: #494949;
}
