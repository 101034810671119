.UnderHeaderMenuComponent {
  background-color: rgba(87, 114, 255, 0.77);
}

.UnderHeaderMenuComponent__inner {
  max-width: 1510px;
  margin: 0 auto;
}

.UnderHeaderMenuComponent__inner_second {
  margin-left: 40px;
  margin-right: 40px;
}

.menuButtonsAll {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 0 auto;
}

.menuButtonsAll__makeAnAppointmentButton {
  width: 191px;
  justify-self: end;
  cursor: pointer;
  background-color: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  color: #ffffff;
}

@media (max-width: 961px) {
  .UnderHeaderMenuComponent {
    display: none;
  }
}

@media (max-width: 600px) {
  .UnderHeaderMenuComponent__inner {
    margin-left: 10px;
    margin-right: 10px;
  }
}