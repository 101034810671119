.newsPage__new_component {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
  background: transparent;
  border-radius: 10px;

  margin: 20px 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.05em;
  font-size: 18px;

  color: #2c2c2c;
}

.newsPage__new_header {
  font-size: 24px;
  margin-bottom: 15px;
  color: #006bcf;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
}

.newsPage__new_description {
  font-size: 16px;
  text-align: left;
}
