.DoctorsPage__header_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1160px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.DoctorsPage__header {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 0.05em;
    margin-left: 30px;
    margin-top: 20px;

    color: #4c4c4c;
}
