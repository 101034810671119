.InfoQualificationsPage {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  font-size: 16px;
  letter-spacing: 0.05em;

  color: #3d3d3d;
}

.InfoQualificationsPage__table {
  border-collapse: collapse;
  width: 100%;
}

.InfoQualificationsPage__thead {
  font-weight: 400;
  font-size: 18px;
}

.InfoQualificationsPage__thead_td {
  padding-left: 20px;
  padding-bottom: 5px;
}

.InfoQualificationsPage__trow_td {
  border-top: 1px solid #ddd;
  padding: 10px 10px;
  margin-right: 20px;
}

.name {
  padding-right: 20px;
}

tbody tr:nth-child(odd) {
  background-color: #ffffff; /* фон нечетных строк */
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* фон четных строк */
}

@media (max-width: 600px) {
  .overrrrrrrr {
    overflow-x: scroll;
    width: 100%;
  }

  .InfoQualificationsPage {
    min-width: 0;
  }

  .InfoQualificationsPage__thead {
    font-size: 12px;
  }

  .InfoQualificationsPage__table {
    width: 100%;
    font-size: 12px;
  }

  .InfoQualificationsPage__trow_td {
    padding: 5px 5px;
  }
}
