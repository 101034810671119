.InfoVacanciesPage__header,
.InfoVacanciesPage_list {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    font-size: 16px;
    letter-spacing: 0.05em;

    color: #3d3d3d;
}

.InfoVacanciesPage_list li {
    list-style: inside;
}
