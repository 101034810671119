.infoMapPageButton__ul {
    margin-left: 40px;
}

.infoMapPageButton {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    color: #3d3d3d;
    font-size: 16px;
    padding: 7px;
    align-items: left;
    cursor: pointer;
    background-color: transparent;
}

.infoMapPageButton__h3 {
    text-align: left;
}

.infoMapPageButton:hover {
    color: #005bb1;
    font-weight: 500;
    /* transform: scale(1.01); */
}