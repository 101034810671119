.aboutClinic {
  max-width: 1160px;
  /* min-width: 1024px; */
  margin: 0 auto;
}

.aboutClinic__text {
  margin-left: 20px;
  margin-right: 20px;
}

.aboutClinic__header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #383838;
  padding-bottom: 10px;
  /* margin-top: 15px; */
}

.aboutClinic__description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  color: #484848;
}

.aboutClinic__aboutClinicBlockComponent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.aboutClinic__hr {
  border-bottom: 1px solid #4c89c1;
  max-width: 960px;
  margin: 0 auto;
}

@media (max-width: 1250px) {
  .aboutClinic__header {
    margin-top: 15px;
  }
}

@media (max-width: 961px) {
  .aboutClinic__aboutClinicBlockComponent {
    width:90%;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
    .aboutClinic__aboutClinicBlockComponent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
