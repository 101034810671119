.servicesColumnComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.servicesColumnComponent__serviceButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: left;
    padding: 12px 0px;
    background-color: transparent;
    cursor: pointer;
}

.servicesColumnComponent_arrow {
    margin-right: 20px;
}

.servicesColumnComponent__service:hover {
color: #000000;
transition: .2s;
transform: scale(1.02);
}
