.ContactsPageComponent__container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.InfoContactPage__info {
  margin: 20px 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.05em;
  font-size: 18px;

  color: #2c2c2c;
}

.ContactsPageComponent__info {
  margin-right: 20px;
}

.ContactsPageComponent__map {
  width: 50%;
}

.InfoContactPage__info_header {
  font-size: 18px;
  margin-bottom: 15px;
  color: #006bcf;
}

.InfoContactPage__info_desc {
  font-size: 16px;
}

.ContactPageInformation__hr_visible {
  border-top: 1px solid #d8d8d8;
  width: 100%;
}

@media (max-width: 961px) {
  .ContactsPageComponent__container {
    flex-direction: column;
  }
  .ContactsPageComponent__map {
    display: none;
  }
}
