.PriceListBlock {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    font-size: 16px;
    letter-spacing: 0.05em;
    width: 100%;

    color: #3d3d3d;
}

.PriceListBlock_header {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
    margin-bottom: 25px;
    margin-top: 15px;

    color: #3281c9;
}

.PriceListComponent__table {
    border-collapse: collapse;
    width: 100%;
}

.PriceListComponent__thead {
    font-weight: 400;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.05em;
}

.PriceListComponent__thead_td,
.PriceListComponent__thead_td_price {
    padding-left: 10px;
    padding-bottom: 5px;
}

.PriceListComponent__thead_td_price {
    min-width: 150px;
}

.PriceListComponent__trow_td {
    border-top: 1px solid #ddd;
    padding: 10px 10px;
    margin-right: 20px;
}

.name {
    padding-right: 20px;
}

tbody tr:nth-child(odd) {
    background-color: #ffffff; /* фон нечетных строк */
}

tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* фон четных строк */
}

.OfertaComponent {
    max-width: 960px;
    margin: 0 auto;
    padding-bottom: 30px;
}

.OfertaComponent__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: linear-gradient(89.89deg, #4c89c1 0%, #9fa6e8 100%);
    border-radius: 10px;
    padding: 15px 30px;
    color: #ffffff;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    margin-left: 20px;
    margin-right: 20px;

    letter-spacing: 0.05em;
}

@media (max-width: 961px) {
    .PriceListComponent__thead_td_price {
        min-width: 0;
    }

}
