.css-ohwg9z {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.css-ohwg9z {
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.css-1f8sh1y {
  max-width: 800px !important;
  margin: 0 auto;
}

.css-1abc02a,
.css-hn784z {
  height: 60% !important;
}

.reviewsComponent__block_header {
  margin-bottom: 20px;
}

.reviewsComponent__block {
  background: url("../../img/reviews.png");
  background-size: auto 100%;
  height: 480px;
  margin-top: 5px;
}

.reviewsComponent__block_header {
  background-color: rgba(255, 255, 255, 0.753);
  margin: 0 auto;
  width: 300px;
  padding: 5px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-top: 5px;
}

.reviewsComponent__block_header_inner {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #383838;
  padding-bottom: 10px;
  padding-top: 10px;
}

.reviewsComponent__block_carousel {
  margin-top: 20px !important;
  max-width: 1000px;
  margin: 0 auto;
  height: 350px;
  border-width: 20px;
  border-color: #333;
}

.carousel {
  position: relative;
  filter: drop-shadow(10px 10px 45px rgba(0, 0, 0, 0.2));
  perspective: 100px;
}

.carousel__slide {
  position: relative;
  background: rgba(255, 255, 255, 0.883);
  border-radius: 30px;
  padding: 30px 60px;
}

.carousel__slide_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  line-height: 1.4rem;
  height: 100%;
}

.carousel__slide_description,
.carousel__slide_name {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  color: #313131;
}

.css-e1mnrr,
.css-zbwuqm {
  display: none;
}

@media (max-width: 961px) {
  .reviewsComponent__block {
    display: none;
  }
}
