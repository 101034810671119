.NewPage__text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  font-size: 16px;
  margin: 7px 0;

  color: #2c2c2c;
}

.NewPage_list {
    padding-left: 20px;
    margin: 20px 0;
}

.text_bold {
    font-weight: 600;
}

ul.NewPage_list li {
    margin: 5px 0px;
}