.servicesBlock {
  max-width: 1160px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.servicesBlock__header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #383838;
  padding-bottom: 10px;
  margin-top: 15px;
}

.servicesBlock__description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #484848;
}

.servicesBlock__availableServices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 20px;
}

@media (max-width: 961px) {
  .servicesBlock {
    display: none;
  }
}
