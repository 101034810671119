.informationBlock {
    background-image: url("../../img/bg_fone.png");
    background-size: 100% 100%;
    padding-top: 20px;
    padding-bottom: 10px;
}

.informationHeading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    /* margin: 0px 10px;  */
    letter-spacing: 0.05em;
    padding: 20px 10px 20px 50px;
    color: #383838;
}

.informationTextContainer {
    margin-left: 20px;
    margin-right: 20px;
}

.informationFrame {
    max-width: 1040px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 15px 15px 45px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    align-items: flex-start;
    padding: 10px 10px 15px;
    border-top: 100px;
    margin: 0 auto;
    padding-bottom: 35px;
    margin-bottom: 30px;
}

.informationUnderLine {
    border-bottom: 1px solid #4c89c1;
    width: 100%;
    max-width: 750px;
}

.informationAboutClinic {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.05em;
    padding: 20px 70px 20px 70px;
    color: #424242;
    margin: 0px 10px;
}

.InformationAboutServises {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 0.05em;
    margin: 0px 35px;
    color: #424242;
}

@media (max-width: 600px) {
    .informationBlock {
        display: none;
    }
}