.menuButtons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 575px;
    border-width: 10px;
    padding: 0px 0px;
}

.menuButton {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    color: #FFFFFF;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    cursor: pointer;
    background-color: transparent;
}

.menuButton__expandArrow {
    padding-left: 6px;
}

.menuButton:hover {
    color: rgb(53, 53, 53);
    background-color: rgba(255, 255, 255, 0.288);
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.514);
}